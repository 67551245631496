import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import MobiuxLogo from '../images/mobiux-logo.svg';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import $ from 'jquery';
import Burger from '@animated-burgers/burger-rotate';
import '@animated-burgers/burger-rotate/dist/styles.css';

export default class Header extends React.Component {
  componentDidMount() {
    var col = false;
    $(document).ready(function () {
      $('.navbar-toggler').click(function () {
        col = !col;
        if (col) {
          $('div.burger').addClass('open');
        } else {
          $('div.burger').removeClass('open');
        }
      });
      $('.nav-link').click(function () {
        $('div.burger').removeClass('open');
      });
    });
  }

  render() {
    return (
      <Navbar collapseOnSelect id='navbar' bg='dark' variant='dark' expand='lg'>
        <Navbar.Brand href='/'>
          <img className='mobiux-logo' src={MobiuxLogo} alt='mobiux logo' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav'>
          <div
            className='burger burger-rotate'
            style={{ fontSize: '10px', transitionDuration: '0.4s' }}
          >
            <div className='burger-lines'></div>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className=''>
            <Nav.Item>
              <Nav.Link eventKey='1' className='text-center p-0'>
                <AniLink
                  cover
                  direction='down'
                  bg='#000000'
                  duration={1}
                  to='/originals'
                  className='nav-link'
                >
                  Originals
                </AniLink>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='1' className='text-center p-0'>
                <AniLink
                  cover
                  direction='down'
                  bg='#000000'
                  duration={1}
                  to='/works'
                  className='nav-link'
                >
                  Works
                </AniLink>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey='2' className='text-center p-0'>
                <AniLink
                  cover
                  direction='down'
                  bg='#000000'
                  duration={1}
                  to='/company/'
                  className='nav-link'
                >
                  Company
                </AniLink>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
