/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/global.scss";

import React from "react";
import { navigate } from "gatsby";

export const wrapPageElement = ({ element, props }) => {
  if (props.location.pathname === '/gitex/') {
    navigate('/');
    return null;
  }

  return element;
};